import * as React from 'react';

import { useAuth } from './auth';

export const ROLES = {
	ADMIN: 'ADMIN',
	USER: 'USER',
	MARKETING: 'MARKETING',
	PROCUREMENT: 'PROCUREMENT',
	DEVELOPER: 'DEVELOPER',
	EVALUATOR: 'EVALUATOR',
};

export const POLICIES = {
	'comment:delete': (user, comment) => {
		if (user.role === 'ADMIN') {
			return true;
		}

		if (user.role === 'USER' && comment.authorId === user.id) {
			return true;
		}

		return false;
	},
	'create:update': (user) => {
		if (
			user.role === 'ADMIN' ||
			user.role === 'PROCUREMENT' ||
			user.role === 'EVALUATOR'
		) {
			return true;
		}
		return false;
	},
	'create:delete': (user) => {
		if (
			user.role === 'ADMIN' ||
			user.role === 'PROCUREMENT' ||
			user.role === 'EVALUATOR'
		) {
			return true;
		}
		return false;
	},
	'create:update:view': (user) => {
		if (
			user.role === 'ADMIN' ||
			user.role === 'PROCUREMENT' ||
			user.role === 'EVALUATOR'
		) {
			return true;
		}
		return false;
	},
};

export const useAuthorization = () => {
	const { user } = useAuth();

	if (!user) {
		throw Error('User does not exist!');
	}

	const checkAccess = React.useCallback(
		({ allowedRoles }) => {
			if (allowedRoles && allowedRoles.length > 0) {
				return allowedRoles?.includes(user.role);
			}

			return true;
		},
		[user.role]
	);

	return { checkAccess, role: user.role };
};

export const Authorization = ({
	policyCheck,
	allowedRoles,
	forbiddenFallback = null,
	children,
}) => {
	const { checkAccess } = useAuthorization();

	let canAccess = false;

	if (allowedRoles) {
		canAccess = checkAccess({ allowedRoles });
	}

	if (typeof policyCheck !== 'undefined') {
		canAccess = policyCheck;
	}

	return <>{canAccess ? children : forbiddenFallback}</>;
};
