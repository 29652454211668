import { ContentLayout } from "components/Layout";
import greetingTime from "greeting-time";
import { useEffect, useState } from "react";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { CgToday } from "react-icons/cg";
import { ImCart } from "react-icons/im";
import { HiOutlineUsers } from "react-icons/hi";

import { useAnalytics } from "../api/getAnalytics";

import { useAuth } from "lib/auth";
import { Accordion, DashboardLoading } from "components/Elements";
import { Card } from "../components/Card";
import { Authorization, ROLES } from "lib/authorization";
import { Unauthorized } from "features/auth/components/Unauthorized";
import { useSales } from "../api/getSales";
import { AverageSales } from "../components/barcharts/AverageSales";
import { SalesByServiceType } from "../components/piecharts/SalesByServiceType";

export const Dashboard = () => {
  const analyticsQuery = useAnalytics();

  const salesQuery = useSales();

  const { user } = useAuth();

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    setGreeting(greetingTime(new Date()));
  }, []);

  if (analyticsQuery.isLoading || salesQuery.isLoading)
    return <DashboardLoading />;

  if (!analyticsQuery.data || !salesQuery.data) return null;

  return (
    <Authorization
      forbiddenFallback={<Unauthorized />}
      allowedRoles={[ROLES.ADMIN, ROLES.DEVELOPER]}
    >
      <ContentLayout title="Dashboard">
        {/* <Breadcrumbs /> */}
        <div className="">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-3">
              <div className="mb-2">
                <p className="text-gray-500 text-lg font-bold">
                  {" "}
                  {greeting} 👋🏼 {user.name}
                </p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2 mb-2">
                <Card
                  link="./orders"
                  icon={<MdOutlineFeaturedPlayList className="text-gray-600" />}
                  content={analyticsQuery.data.orders}
                  label="Orders"
                />
                <Card
                  link="./users"
                  icon={<HiOutlineUsers className="text-gray-600" />}
                  content={analyticsQuery.data.users}
                  label="Users"
                />
                <Card
                  link="./daily-deals"
                  icon={<CgToday className="text-gray-600" />}
                  content={analyticsQuery.data.dailydeals}
                  label="Daily Deals"
                />

                <Card
                  link="./carts"
                  icon={<ImCart className="text-gray-600" />}
                  content={analyticsQuery.data.carts}
                  label="Carts"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 space-y-3">
          <Accordion title="Average Sales">
            <div className="w-[50%] mx-auto">
              <AverageSales salesData={salesQuery.data} />
            </div>
          </Accordion>
          <Accordion title="Sales By Service Type">
            <div className="w-[50%] mx-auto">
              <SalesByServiceType salesData={salesQuery.data} />
            </div>
          </Accordion>
        </div>
      </ContentLayout>
    </Authorization>
  );
};
