import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getSales = () => {
  return axios.get("/sales");
};

export const useSales = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["sales"],
    queryFn: () => getSales(),
  });
};
