import Select from 'react-select';
import { FieldWrapper } from './FieldWrapper';


export const ReactSelectField = props => {
  const {
    label,
    options,
    error,
    setValue,
    name,
    onInputChange,
    required,
    defaultValue,
    onMenuClose,
    isMulti = false,
  } = props;

  const onSelectStateChange = selectState => {
    setValue(name, selectState ? selectState.value : null);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        onMenuClose={onMenuClose}
        name={name}
        required={required}
        isMulti={isMulti}
        onInputChange={onInputChange}
        defaultValue={defaultValue}
        options={options}
        onChange={onSelectStateChange}
        maxMenuHeight={100}
      />
    </FieldWrapper>
  );
};
