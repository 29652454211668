import React from "react";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs';
import { formatDate } from "utils/format";
import { Button } from "../Button";

export const ExportWorkBook = ({ data, title }) => {
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    const fileBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([fileBuffer], { type: "application/octet-stream" }),
      `${title}-${formatDate(new Date())}.xlsx`
    );
  };

  return (
    <Button size="sm" variant="inverse" onClick={handleExport} type="button">
      Export Data
    </Button>
  );
};
