import logo from "assets/logo.png";
import React from "react";
import { Spinner } from "components/Elements";

export const DashboardLoading = () => {
  return (
    <div
      id="loading-screen"
      className="w-full h-full fixed block top-0 left-0 bg-white opacity-100"
    >
      <div className="opacity-100 my-40 mx-10 md:mx-40 flex flex-col">
        <div className="flex justify-start">
          <div className="flex items-center text-white">
            <img className="h-10 md:h-24 w-auto" src={logo} alt="Workflow" />
          </div>
        </div>
        <div className="text-sm md:text-2xl font-bold my-4">
          The Shop Dashboard is loading.
        </div>
        <Spinner size="sm" />
      </div>
    </div>
  );
};
