import logo from "assets/logo.png";
import { Link } from "components/Elements";
import { Head } from "components/Head";
import PropTypes from "prop-types";
import { LightningBoltIcon } from "@heroicons/react/solid";

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Link className="flex items-center text-white" to="/">
              <img className="h-20 w-auto" src={logo} alt="Workflow" />
            </Link>
          </div>

          <h2 className="mt-3 text-center text-xl font-extrabold text-gray-900">
            {title}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {children}
          </div>
        </div>
        <div className="bg-gray-100 shadow-lg text-gray-700 py-4 mt-24 rounded-3xl md:mx-32 px-24">
          <div className="container mx-auto flex items-center justify-between">
            <p className="text-sm">
              © 2024 National Curriculum Development Center. All rights
              reserved.{" "}
              <a href="https://eshop.ncdc.go.ug/terms-conditions" target="_blank" rel="noreferrer">Terms and conditions</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/dispute" target="_blank" rel="noreferrer">Dispute Resolution Policy</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/IPR" target="_blank" rel="noreferrer">IPR Protection Policy</a> .{" "}
            </p>
            <div className="flex items-center justify-center">
              <p className="text-center text-xs text-gray-500 flex flex-row items-center space-x-1">
                <LightningBoltIcon className="h-3 text-orange-500" />
                
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
