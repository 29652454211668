import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PieController,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, PieController);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Sales by Service Type",
    },
  },
};

export function SalesByServiceType({ salesData }) {
  // Aggregate sales amount by type_of_service
  const salesByService = salesData.reduce((acc, curr) => {
    
    const { order } = curr;
    if (!acc[order?.type_of_service]) {
      acc[order?.type_of_service] = 0;
    }
    acc[order?.type_of_service] += order?.amount;
    return acc;
  }, {});

  // Extracting service types and total sales amounts for the chart
  const labels = Object.keys(salesByService);
  const data = Object.values(salesByService);

  // Generate random colors for the chart
  const backgroundColors = Array.from({ length: labels.length }, () =>
    getRandomColor()
  );

  const datasets = [
    {
      data,
      backgroundColor: backgroundColors,
    },
  ];

  return (
    <Pie
      options={options}
      data={{ labels, datasets }}
    />
  );
}

// Function to generate random colors
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
