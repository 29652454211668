import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const Card = ({ link, icon, content, label }) => {
  return (
    <div className="bg-white rounded-lg border border-gray-300 shadow-md p-4 text-gray-800 hover:bg-green-800 hover:text-white">
      <Link to={link}>
        {icon}
        <div className="flex flex-row items-center space-x-2">
          <div className="font-bold text-2xl">{content}</div>
        </div>
        <span className="text-xs font-bold">{label}</span>
      </Link>
    </div>
  );
};

Card.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
};
