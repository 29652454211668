import { LightningBoltIcon } from "@heroicons/react/solid";
import logo from "assets/logo.png";
import { Button } from "components/Elements";
import { Head } from "components/Head";
import { useAuth } from "lib/auth";
import { useNavigate } from "react-router";

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = () => {
    if (user) {
      navigate("/app");
    } else {
      navigate("/auth/login");
    }
  };

  return (
    <>
      <Head description="EShop Portal" />
      <div className="h-[90vh] flex items-center">
        <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="flex flex-row justify-center my-4">
            <img src={logo} alt="react" className="h-20 w-auto" />
          </div>
          <div className="font-bold text-lg">
            Welcome to the NCDC E-shop Management Portal
          </div>

          <div className="mt-8 flex justify-center">
            <Button
              onClick={handleStart}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
              }
            >
              {user ? `Welcome Back ${user.name}` : "Go to Login"}
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 shadow-lg text-gray-700 py-4 rounded-3xl md:mx-32 px-24">
          <div className="container mx-auto flex items-center justify-between">
            <p className="text-sm">
              © 2024 National Curriculum Development Center. All rights
              reserved.{" "}
              <a href="https://eshop.ncdc.go.ug/terms-conditions" target="_blank" rel="noreferrer">Terms and conditions</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/dispute" target="_blank" rel="noreferrer">Dispute Resolution Policy</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> .{" "}
              <a href="https://eshop.ncdc.go.ug/IPR" target="_blank" rel="noreferrer">IPR Protection Policy</a> .{" "}
            </p>
            <div className="flex items-center justify-center">
              <p className="text-center text-xs text-gray-500 flex flex-row items-center space-x-1">
                <LightningBoltIcon className="h-3 text-orange-500" />
                
                
              </p>
            </div>
          </div>
        </div>
    </>
  );
};
