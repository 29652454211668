import { DashboardLoading } from "components/Elements";
import {
  loginWithEmailAndPassword,
  getUser,
  registerWithEmailAndPassword,
  logout,
  refreshLoginToken,
} from "features/auth";
import storage from "utils/storage";
import { initReactQueryAuth } from "react-query-auth";

async function handleUserResponse(data) {
  const { token, user } = data;
  storage.setToken(token);
  return user;
}

async function loadUser() {
  if (storage.getToken()) {
    const data = await getUser();

    return data;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function refetchUser() {
  const response = await refreshLoginToken();
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  const response = await logout();
  storage.clearToken();
  return response;
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  refetchUser,
  logoutFn,
  LoaderComponent() {
    return <DashboardLoading />;
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
