import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { formatDate } from "utils/format";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Sales over time",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export function AverageSales({ salesData }) {
  // Assuming your salesData has a structure similar to the provided example
  const labels = salesData.map((item) => formatDate(item.payment_date));
  const datasets = [
    {
      label: "Sales Amount",
      data: salesData.map((item) => item.amount),
      backgroundColor: "rgb(26, 166, 57)",
    },
    // You can add more datasets as needed
  ];

  const data = {
    labels,
    datasets,
  };

  return <Bar options={options} data={data} />;
}
