import { Route, Routes } from 'react-router-dom';
import { Authorization401 } from './Authorization401';
import { ForgotPassword } from './ForgotPassword';

import { Login } from './Login';
import { ResetPassword } from './ResetPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="login" element={<Login />} />
      <Route path="unauthorized" element={<Authorization401 />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
};
