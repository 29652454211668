import { useEffect } from "react";
import { AppProvider } from "./providers/app";
import { AppRoutes } from "./routes";

function App(props) {
  useEffect(() => {
    document.body.classList.add("bg-white");
  });
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
